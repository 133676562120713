import React, { Component } from "react";
import { connect } from "react-redux";
import LoaderOverlay from "@components/global/loader-overlay";
import { handleLogOut } from "@actions/auth/auth-logout";
import {
  removeGuestToken,
  logout,
} from "@services/http_services/to_ttf_requests";
import { showToast } from "@actions/ui";
import { deleteCookieByName } from "@utils/cookies";
import { clearCheckoutData } from "@actions/checkout";
import { navigate } from "gatsby";

class Logout extends Component {
  componentDidMount = async () => {
    const { handleLogOut, showToast, clearCheckoutData } = this.props;

    try {
      await logout();
      handleLogOut();
      removeGuestToken();
      clearCheckoutData();
      deleteCookieByName("X-TF-ECOMMERCE");
      navigate("/");
    } catch (e) {
      showToast("Something Wrong");
    }
  };

  render() {
    return <LoaderOverlay />;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleLogOut: () => {
      dispatch(handleLogOut());
    },
    showToast: (message, intentStyle, forever) => {
      dispatch(showToast(message, intentStyle, forever));
    },
    clearCheckoutData: () => {
      dispatch(clearCheckoutData());
    },
  };
};

const LogoutRedux = connect(null, mapDispatchToProps)(Logout);

export default LogoutRedux;

export const Head = () => <title>Home Page</title>;
